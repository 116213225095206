import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Sidebar from "../components/sidebar/sidebar"
import HomeScreen from "../components/homescreen/homescreen"
import Sobre from "../components/intro/sobre"
import Montagem from "../components/montagem/sobreMontagem"
import Lista from "../components/vinhos/sobreTopVinhos"
import Regioes from "../components/regioes/regioes"
import Artigo from "../components/artigos/artigos"
import Equipa from "../components/equipa/equipa"
import News from "../components/indexNews/indexNews"
import Social from '../components/social'
import Footer from "../components/footer/footer"
import SidebarMobile from '../components/sidebar/mobile'

const LuaCheiaPage = ({data}) => {
  const breakpoints = useBreakpoint();
  return(
    <Layout color="#150e04">
      <SEO title="Lua Cheia" />
      {!breakpoints.mobile ?
        <Sidebar switchMenu="left"/>
      :
        <SidebarMobile brown/>
      }
      <HomeScreen data={data.sobreluacheiaJson.homeScreenImg.childImageSharp.fluid} json='luacheia' luacheia/>
      <div id='visit'/>
      <Sobre json='luacheia' year/>
      <Social/>
      <Montagem data={data.sobreluacheiaJson.montagem}/>
      <Lista data={data.sobreluacheiaJson.topVinhos} json='luacheia' luaCheia/>
      <Regioes json='luacheia'/>
      <Artigo data={data.sobreluacheiaJson.artigos}/>
      <Equipa data={data.equipaJson.equipa} luacheia/>
      <News data={data.mediaJson.banner.imgbanner.childImageSharp.fluid}/>
      <Footer/>
    </Layout>
  )
}

export default LuaCheiaPage


export const Json = graphql`
  query luaCheia {
    sobreluacheiaJson {
      homeScreenImg{
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      montagem{
        img1{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img2{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      topVinhos{
        list{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          ano
        }
      }
      artigos{
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    equipaJson{
      equipa{
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    mediaJson{
      banner{
        imgbanner{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`