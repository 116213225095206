import React from "react"
import Img from "gatsby-image"
import { device } from "../device"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

const Equipa = ({ data, luacheia, saven, light }) => {
  const {t} = useTranslation(['equipa'])
  const breakpoints = useBreakpoint();

  return(
    <StyledEquipa light={light}>
      {t('equipa', {returnObjects: true}).map(({titleSaven, titleLua, name, subtitle, text}, i) => (
          <>
            {(saven && titleSaven) &&
              <div className={"profile profile" + i}>
                {!breakpoints.mobile &&
                  <div className="profileImg">
                    <Img fluid={data[i].img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }}/>
                  </div>
                }
                <div className="profileContent">
                  <div className="profileTxt">
                    <h5 className={font.nSB + " red"}>{titleSaven}</h5>
                    <h3 className={font.nSR}>{name}</h3>
                    <h5 className={font.nSI}>{subtitle}</h5>
                    {breakpoints.mobile && <Img fluid={data[i].img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }} className='img-mobile'/>}
                    <p className={font.nSR + " text"}>{text}</p>
                  </div>
                </div>
              </div>  
            }

            {(luacheia && titleLua) &&
              <div className={"profile profile" + i}>
                {!breakpoints.mobile &&
                  <div className="profileImg">
                    <Img fluid={data[i].img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }}/>
                  </div>
                }
                <div className="profileContent">
                  <div className="profileTxt">
                    <h5 className={font.nSB + " red"}>{titleLua}</h5>
                    <h3 className={font.nSR}>{name}</h3>
                    <h5 className={font.nSI}>{subtitle}</h5>
                    {breakpoints.mobile && <Img fluid={data[i].img.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }} className='img-mobile'/>}
                    <p className={font.nSR + " text"}>{text}</p>
                  </div>
                </div>
              </div>  
            }
          </>
      ))}
    </StyledEquipa>
  )
}

export default Equipa

const StyledEquipa = styled.div`
  position: relative;
  max-width: 700px;
  margin: 0 auto;

  @media ${device.desktop}{
    max-width:unset;
    margin:unset;
    margin: 5vw 0 6vw 200px;
  }

  @media ${device.desktopL}{
    margin-left: 15.65%;
  }

  .profile:nth-child(even){
    flex-direction:row-reverse;
  }

  .profile{
    width: 100%;

    @media ${device.desktop}{
      display: flex;
      flex-direction: row;
      height: 500px;
      margin:85px 0;
    }

    @media ${device.desktopXL}{
      min-height: 775px;
    }

    .profileImg{
      width: 100%;

      @media ${device.desktop}{
        width: 70%;
      }

      @media ${device.desktopM}{
        width:50%;
      }

      .gatsby-image-wrapper{
        height:100%;
      }
    }

    .profileContent{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction:column;
      align-items: center;
      text-align: center;
      padding:40px 25px;
      color: ${props => props.light ? '#3e3028' : '#fff8f4'};

      @media ${device.desktop}{
        flex-direction:unset;
        justify-content: space-around;
        padding: 30px;
      }

      @media ${device.desktopM}{
        width:50%;
      }

      .button-container{
        display:flex;
        justify-content:center;
        margin-bottom:20px;
      }

      .setaBtn{
        .setaImg{
          width: 50px;
          filter: ${props => props.light && 'invert(1)'};
        }
      }

      .profileTxt{
        width: 100%;
        
        .red{
          color: #fa4f4f;
          letter-spacing: 2px;
          text-transform: uppercase;
          width: 70%;
          margin:0 auto;
          margin-bottom: 20px;
        }

        .img-mobile{
          margin: 20px 0;
        }
        
        .text{
          font-size:18px;
          max-height: 250px;
          overflow-y: auto;

          @media ${device.desktop}{
            margin-top: 50px;
            font-size:20px;
            padding: 0 10px;
            margin-left:15px;
            margin-right:15px;
          }

          @media ${device.desktopM}{
            padding:0 45px;
          }
        }

        .text::-webkit-scrollbar {
          width: 3px;
          background-color: ${props => props.light ? '#fff' : '#3e3028'};
        }
        .text::-webkit-scrollbar-thumb {
            height: 6px;
            background-clip: padding-box;
            background-color: ${props => props.light ? '#3e3028' : '#fff'};
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        }
        .text::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
        }
        .text::-webkit-scrollbar-corner {
            background-color: transparent;
        }
      }
    }
  }


  /* react-transition-group */
  .switch-enter {
      opacity: 0;
      top: 0%;
  }

  .switch-enter-active {
      opacity: 1;
      top: 0%;
      transition: opacity 350ms ease-out;
  }

  .switch-exit {
      opacity: 1;
      position: absolute;
      top: 0%;
  }

  .switch-exit-active {
      opacity: 0;
      top: 0%;
      transition: opacity 350ms ease-out;
  }
`
