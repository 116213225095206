import React from "react"
import Img from "gatsby-image"
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { device } from "../device"

import styled from "styled-components"

// import font from "../../fonts/fonts.module.scss"
// import logo from "../../images/logos/lua_small_logo.svg"

const Montagem = ({ data }) => {
  // const breakpoints = useBreakpoint();
  return(
    <StyledMontagem>
      <Img fluid={data.img1.childImageSharp.fluid} imgStyle={{ objectFit: "cover" }} className='first-img'/>
      {/* {!breakpoints.mobile && 
        <Img fluid={data.img2.childImageSharp.fluid} style={{ width: `35%`, position: "absolute" }} imgStyle={{ objectFit: "container" }} className="img2"/>
      } */}
      {/* <div className="montCont">
        <img src={logo} alt="Logo da Lua Cheia - Saven"/>
        <p className={font.nSR + " montText"}>{data.text}</p>
      </div> */}
    </StyledMontagem>
  )
}

export default Montagem

const StyledMontagem = styled.div`
    position: relative;

    @media ${device.desktop}{
      margin: 0 14px 0 200px;
    }

    @media ${device.desktopL}{
      margin-left: 15.65%;
    }


    .first-img{
      width:100%;
      max-height: 700px;

      /* @media ${device.desktop}{
        width: 70%
      } */
    }

    .img2{
      top: 8vw;
      right: 9vw;
    }
    .montCont{
      margin: 3vw;
      width: 37vw;
      display: flex;
      .montText{
        margin-left: 1vw;
        color: #fff8f4;
      }
    }
`
