import React from "react"

import styled from "styled-components"
import { device } from '../device'

import face from "../../images/social/face.svg"
import insta from "../../images/social/insta.svg"

const Footer = () => (
  <StyledSocial>
    <div className="segment right">
      <div className="segTitle social">
        <a href="https://www.facebook.com/LuaCheiaSaven" className="socialLink" target="_blank" rel="noreferrer"><img src={face} alt="Logo do Facebook" className="logoSmall"/></a>
        <a href="https://www.instagram.com/luacheia_saven" className="socialLink" target="_blank" rel="noreferrer"><img src={insta} alt="Logo do Instagram" className="logoSmall"/></a>
      </div>
    </div>
  </StyledSocial>
)

export default Footer

const StyledSocial = styled.div`
    background: #150e04;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    font-size: 14px;
    color: #fff8f4;
    padding: 40px 25px;
    margin:0 auto;
    margin-bottom: 9vw;

    @media ${device.desktop}{
      width: 35%;
      justify-content: center;
      margin-top:15px;
    }

    .segment{
      text-align:center;
      margin:10px 0;

      @media ${device.tabletM}{
        text-align:left;
        margin:0;
      }

        p{
          font-size: 14px;
        }
    }

    .social{
      display: flex;

      &>a{
        margin: 0 10px;
      }
    }

    .right{
      display: flex;
      flex-direction: column;
      align-items:center;
      margin:0%;
    }
`
