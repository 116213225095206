import React from "react"
import Img from "gatsby-image"
import Div100vh from 'react-div-100vh'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"
import { device } from "../device"

// import gsap from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
// import { ScrollToPlugin } from 'gsap/all'

// if (typeof window !== `undefined`) {
//   gsap.registerPlugin(ScrollTrigger)
//   gsap.registerPlugin(ScrollToPlugin)
//   gsap.core.globals("ScrollTrigger", ScrollTrigger)
//   gsap.core.globals("ScrollToPlugin", ScrollToPlugin)
// }

const Artigo = ({ data }) => {
  const {t} = useTranslation(['luacheia'])
//   const [triggerRefresh, setTrigger] = useState(0)
//   const sections = document.querySelectorAll(".container");

//   function goToSection(section) {
//     gsap.to(window, {
//       scrollTo: {y: section, autoKill: false},
//       duration: 0.2,
//       delay: 0.1,
//       ease: "none",
//     });
//   }

//   useEffect(() => {
//     sections.forEach((section, i) => {
//       gsap.timeline({
//         paused: true,
//         scrollTrigger: {
//           trigger: section,
//           onEnter: ()=>{goToSection(section[i])}
//         }
//       })

//       gsap.timeline({
//         paused: true,
//         scrollTrigger: {
//           trigger: section,
//           start: "bottom bottom",
//           onEnterBack: () => goToSection(section[i])
//         }
//       })
//     });
//   }, [triggerRefresh, sections])

  return(
    <StyledArtigo>
        {t('artigos', {returnObjects: true}).map(({title, text}, i) => (
          <StyledDiv className="container" key={i}>
            <Img fluid={data[i].img.childImageSharp.fluid} style={{ width: "100%", height: `100%`, position: "relative" }} imgStyle={{ objectFit: "cover" }} className="img" /*onLoad={()=>{setTrigger(triggerRefresh+1)}}*//>
            <div className="article">
              <h2 className={font.nSR + " artTitle"}>{title}</h2>
              <p className={font.nSR + " artTxt"}>{text}</p>
            </div>
          </StyledDiv>
        ))}
    </StyledArtigo>
  )
}

export default Artigo

const StyledArtigo = styled.div`
    position: relative;

    @media ${device.desktop}{
      margin: 0 14px 100px 200px;
    }

    @media ${device.desktopL}{
      margin-left: 15.65%;
    }

    .container{
      position: relative;
      min-height:100vh;

      @media ${device.desktop}{
        min-height:unset;
      }
    }

    .article{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #fff8f4;
      width:100%;
      padding:0 25px;
      max-width: 700px;
      margin: 0 auto;

      @media ${device.desktop}{
        max-width:unset;
        margin:unset;
        width: 40%;
        padding:unset;
      }

      .artTitle{
        letter-spacing: -2.3px;
        margin-bottom: 3vw;
        font-size:30px;

        @media ${device.desktop}{
          font-size:46px;
        }
      }

      .artTxt{
        font-size:16px;
        margin-top: 30px;

        @media ${device.desktop}{
          font-size:20px;
          margin-top:unset;
        }
      }
    }
`

const StyledDiv = styled(Div100vh)`
  width: 100%;
`
